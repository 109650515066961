<template>
  <div>
    <v-container>
      <page-title title="Dashboard"></page-title>

      <stat1 v-if="modulePermission('dashboard', 'stat1')" class="mb-2"></stat1>

      <card-expansion title="Waiting Approval" no-padding v-if="show_waiting_approval">
        <v-tabs v-model="waiting_approval_tab" class="py-0 my-0">
          <v-tab v-if="modulePermission('dashboard', 'return-material-waiting-approval')">Return Material</v-tab>
          <v-tab v-if="modulePermission('dashboard', 'berita-acara-waiting-approval')">Berita Acara</v-tab>
          <v-tab v-if="modulePermission('dashboard', 'mjo-waiting-approval')">MJO Approval</v-tab>
        </v-tabs>
        <v-tabs-items v-model="waiting_approval_tab">
          <v-tab-item v-if="modulePermission('dashboard', 'return-material-waiting-approval')">
            <rmp-approval class="mb-2"></rmp-approval>
          </v-tab-item>
          <v-tab-item v-if="modulePermission('dashboard', 'berita-acara-waiting-approval')">
            <berita-acara-approval class="mb-2"></berita-acara-approval>
          </v-tab-item>
          <v-tab-item v-if="modulePermission('dashboard', 'mjo-waiting-approval')">
            <mjo-approval class="mb-2"></mjo-approval>
          </v-tab-item>
        </v-tabs-items>
      </card-expansion>
    </v-container>
  </div>
</template>

<script>
import Stat1 from './components/Stat1.vue'
import RmpApproval from './components/RmpApproval.vue';
import BeritaAcaraApproval from './components/BeritaAcaraApproval.vue';
import MjoApproval from './components/MjoApproval.vue';
export default {
  name: "Dashboard",
  components: {
    Stat1,
    RmpApproval,
    BeritaAcaraApproval,
    MjoApproval,
  },
  data() {
    return {
      waiting_approval_tab: null
    }
  },
  computed: {
    show_waiting_approval() {
      if (this.modulePermission('dashboard', 'return-material-waiting-approval')) return true;
      if (this.modulePermission('dashboard', 'berita-acara-waiting-approval')) return true;
      return false;
    },

  },
  methods: {

  },
  mounted() {
    this.requiredLogin();
  },
};
</script>