<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <v-card class="striptop" color="">
          <v-card-title class="text-caption">All Campaign</v-card-title>

          <v-card-subtitle class="pt-6">
            <span class="text-total">{{ numberFormat(data.total_campaign) }}</span>
          </v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="redirect('Sales.Campaign')" v-if="modulePermission('campaign', 'view')">Detail</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <v-card color="">
          <v-card-title class="text-caption text-muted">Registered Campaign</v-card-title>

          <v-card-subtitle class="pt-6">
            <span class="text-total">{{ numberFormat(data.total_registered_campaign) }}</span>
          </v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="redirect('Setting.RegisteredCampaign')" v-if="modulePermission('registeredcampaign', 'view')">Detail</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <v-card color="">
          <v-card-title class="text-caption">Closed Campaign</v-card-title>

          <v-card-subtitle class="pt-6">
            <span class="text-total">{{ numberFormat(data.total_closed_campaign) }}</span>
          </v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="redirect('Sales.Campaign')" v-if="modulePermission('campaign', 'view')">Detail</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data(){
    return {
      loading: null,
      data: 100,
    }
  },

  methods: {
    async getData(refresh) {
      this.loading = true;

      if(refresh) this.AxiosStorageRemove("GET", "dashboard/stat1");

      var params = {
        status: 'open'
      }
      await this.$axios
        .get("dashboard/stat1", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.data = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
  },

  mounted(){
    this.getData()
  },
}
</script>

<style scoped>
.text-total{
  font-size: 36px !important;
}
</style>